import React from "react";
import './WithDrawReady.css';
import Button from '../button/Button'
import ready from '../../img/ready/ready.svg'


const WithDrawReady = ({ setReadyShow,...props }) => {

    return (
        <div className="conWal_back">
            <div className="conWal_wrapper" >
                <div className="WDReadyIMG"><img src={ready} alt="" /></div>
                <div className="WDReadyTextL">Ready!</div>
                <div className="WDReadyTextS">Сoins withdrawn.</div>
                <div className="WDReadyButton" onClick={()=>{setReadyShow(false)}}><Button name="Back" style="green" /></div>
            </div>
        </div>

    )
}

export default WithDrawReady