import React, {useCallback, useContext} from "react";
import './Footer.css';
import footerLogo from '../../../src/img/footer/footerLogo.png';
import plus from '../../../src/img/footer/plus.png';
import { NavLink } from 'react-router-dom';

const tokenAddress = '0x4Ad1a440eF7c85187Ca5aD2Daa27244d499Ad34c';
const tokenSymbol = 'XNFT DAO';
const tokenDecimals = 18;
const tokenImage = 'https://etherscan.io/images/main/empty-token.png';

const Footer = (...props) => {
const handleAddToken = useCallback(async () => {
    console.log('handleAddToken');
    try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20', // Initially only supports ERC20, but eventually more!
                options: {
                    address: tokenAddress, // The address that the token is at.
                    symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: tokenDecimals, // The number of decimals in the token
                    image: tokenImage, // A string url of the token logo
                },
            },
        });

        if (wasAdded) {
            console.log('Thanks for your interest!');
        } else {
            console.log('Your loss!');
        }
    } catch (error) {
        console.log(error);
    }
}, {})
    return (
        <footer className='footer'>
            <div className="backGroundFooter"/>
            <div className="footerButton" onClick={handleAddToken}>add coin to wallet<img src={plus} alt="footerLogo" /></div>
            <div className="footerLinks">
                <div className="footerLink">Contact&nbsp;us:&nbsp;<a href="mailto:x@xnftdao.io" className="green">x@xnftdao.io</a></div>
                <div className="footerLink grey">©&nbsp;All&nbsp;rights reserved</div>
                <div className="footerLink"><NavLink to="/privacy_policy" className="green">Privacy&nbsp;Policy</NavLink></div>
            </div>
            <div className="footerLogo">
                <img src={footerLogo} alt="footerLogo" />
            </div>
        </footer>
    )
}

export default Footer
