import React, { useState } from 'react';
import './ConnectWallet.scss'
import Cross from '../../components/cross/Cross'
import ListQR from '../../components/ListQR/ListQR'
import QRCod from '../../components/qrCod/QRCod'

const ConnectWallet = (props) => {
    //  {ConnectWalletShow} показать модальное окно wallet
    const [ConnectWalletShow, setConnectWalletShow] = useState(false);

    // {countQR}   true показать QRCod , false список qr кодов
    const [countQR, setcountQR] = useState(true);
    return (
        <>{ConnectWalletShow ?
            <div className="conWal_back">
                <div className="conWal_wrapper" >
                    <Cross set={setConnectWalletShow} color="black" />
                    <div className="conWalTitle">
                        Connect wallet
                    </div>
                    <div className="conWalContainerBotton">
                        <div className="conWalButton lineEffect" onClick={() => setcountQR(true)}>QR code</div>
                        <div className="conWalButton greenButton" onClick={() => setcountQR(false)}>Desktop</div>
                    </div>
                    {countQR
                        ?
                        <QRCod />
                        :
                        <div className="listQR">
                            <ListQR />
                        </div>
                    }

                </div>
            </div> : <></>}
        </>
    )
}

export default ConnectWallet