import React from "react";
import './Cross.css';



const Cross = ({ set, color, ...props }) => {

    return (
        <>
            <div className={`menu_cross ${color}`} onClick={() => set(false)}></div>
        </>
    )
}

export default Cross