import React from "react";
import './ConvertButton.scss';
import bounding from '../../img/icon/swap_vert.svg'


const ConvertButton = ({ activ, disabled, ...props }) => {

    return (
        <>
            <button type='button' className={`converButton ${activ ? "activ" : ''} ${disabled ? "disabled" : ''}`}>
                <img src={bounding} alt="bounding" />
            </button>
        </>
    )
}

export default ConvertButton