import React from 'react';
import './Home.css';
import Header from '../header/Header'
import Body from '../body/Body'
import Footer from '../footer/Footer'
import Account from '../account/Account'
import Police from '../police/Police'
import Withdraw from '../withdraw/Withdraw'
import {
    Routes,
    Route
} from "react-router-dom";

const Home = (props) => {
    return (
        <>
            <div className="wrapper">
                <div className="container">
                    <Header className='header' />
                    <div className="main">
                        <Routes>
                            <Route path="/account" element={<Account />}></Route>
                            <Route path="/privacy_policy" element={<Police />}></Route>
                            <Route path="/withdraw" element={<Withdraw />}></Route>
                            <Route path="/" element={<Body />}></Route>
                            <Route path="*" element={<Body />}></Route>

                        </Routes>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
export default Home