export const getSiteSettings = async () => {
    try {
        const result = await fetch('/settings.json');
        const settings = (await result.json());
        return settings;
    } catch(e) {
        console.log('fetch xnft rate error: ' + e.message);
        return 1;
    }
}
