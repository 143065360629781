import React from "react";
import './WithDrawVariant.css';
import loader from '../../img/withdraw/loaderWhite.svg'
import ok from '../../img/withdraw/okWhite.svg'

const WithDrawVariant = ({number,cod,quantity,name, loading, activ,...props }) => {

    return (
        <div className={ `WDVariantContainer ${activ && 'activ'}`}> 
            <div className="WDVariantNumber">{number}</div>
            <div className="WDVariantCod">{cod}</div>
            <div className="WDVariantQuantity">{quantity}</div>
            <div className="WDVariantName">{name}</div>
            <div className="WDVariantСondition">
                {loading?
                    <img className="inputWPloader" src={loader} alt="loader" />
                    :
                    <img  src={ok} alt="loader" />
                }
                
                </div>
        </div>

    )
}

export default WithDrawVariant