import React, {useCallback, useContext, useState} from 'react';
import './Burger.scss';

import {NavLink} from 'react-router-dom';
import Cross from '../cross/Cross';
import Button from '../button/Button';
import ButtonConnect from '../buttonConnect/ButtonConnect';
import Footer from '../../wrapper/footer/Footer'
import {GlobalContext} from "../../context";


const Burger = (isConnectWallet, setisConnectWallet) => {
    const [count, setCount] = useState(false);
    const context = useContext(GlobalContext);
    const {connected, connectWeb3, logout, address} = context;
    const buttonAction = useCallback(() => {
        connected? logout(): connectWeb3();
    } ,[connected])
    return (
        <>


            <div className="burger" onClick={() => setCount(true)}>
                <div className="burger_circle"/>
                <div className="burger_circle"/>
                <div className="burger_circle"/>
            </div>
            {count ? <div className="menu" onClick={() => setCount(false)}>
                <Cross set={setCount} color="wigth"/>
                <div className="menuBody">
                    <ButtonConnect connectButton={connected} buttonAction={buttonAction} address={address} name="Connect wallet"
                                   style="halvetransparent"/>

                    <NavLink to="/account"><Button name="My account" style="transparent"/></NavLink>
                </div>
                <Footer/>
            </div> : <> </>}

        </>
    )
}

export default Burger
