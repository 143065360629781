import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import './Body.css'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'
import ConvertButton from '../../components/convertButton/ConvertButton'
import XNFT from '../../img/icon/XNFT.png'
import WETH from '../../img/icon/WETH.png'
import WETH_white from '../../img/icon/WETH_white.png'
import hors from '../../img/icon/hors.png'
import arrowUp from '../../img/icon/arrow-up.svg'
import {GlobalContext} from "../../context";

const errorXNFT = false
const errorWETH = false

const Body = (props) => {
    const context = useContext(GlobalContext)
    const xnftSettings = useMemo(() => context.xnftSettings, [context]);
    console.log(xnftSettings);
    const [xnftValue, setXnftValue] = useState(0);
    const [wethValue, setWethValue] = useState(0);
    return (
        <>
            <div className="backGroundImg"></div>
            <div className="backGroundImg backGroundLeft"></div>
            <div className="block1">
                <h1>XNFT DAO </h1>
                <div className="titleLine"></div>
                <div className="titleCost">
                    <img src={WETH_white} className="costImg" alt="WETH_white"/>
                    <div className="block1Text">{xnftSettings.xnftRate}$</div>

                </div>

                <div className="prosent">
                    <img src={arrowUp} className="prosentImg" alt="arrowUp"/>
                    {xnftSettings.xnftPercent.toString().replace('.', ',')}%
                </div>
            </div>
            <div className="block2">
                <p>
                    The XNFT DAO fund provides outstanding earning opportunities in a market where 98% lose money.
                </p>
                <p>
                    Our strategy is simple, and we have instruments for getting precious NFT pieces unavailable to most
                    players on the market.
                </p>
            </div>
            <div className="block3">
                <h2>Swap your WETH into XNFTDAO to join us!</h2>
            </div>
            <div className="block4">
                <Input
                    value={xnftValue}
                    onChange={(e) => {
                        const digit = parseFloat(e.target.value);
                        setXnftValue(digit)
                        setWethValue(digit * xnftSettings.xnftRate)
                    }}
                    activ={false} error={errorXNFT} icon={XNFT} topText="XNFT" downText="XNFT DAO" number="0"/>

                <Input
                    value={wethValue}
                    onChange={(e) => {
                        const digit = parseFloat(e.target.value);
                        setWethValue(digit)
                        setXnftValue(digit / xnftSettings.xnftRate)
                    }}
                    activ={false} error={errorWETH} icon={WETH} topText="WETH" isWeth='weth' downText="wrapped ether"
                       number="0"/>
                <ConvertButton activ={false} disabled={false}/>
            </div>
            {(errorXNFT || errorWETH) ?
                <div className="errorText">Not enought ETR to pay transaction fees</div> : <></>}
            <div className="block5">
                <Button name="Deposit" style="green" type={'button'}
                        onClick={() => window.location.assign('https://client.aragon.org/?#/xnftdao/0x8b88667a3258a29fde7175d4d83633195d12dfd0/')}/>

                <Button name="withdraw" style="transparent"/>

            </div>
            <div className="block6">
                <img src={hors} alt="hors"/>
                <span> SWAP ETH -> WETH</span>

            </div>

        </>
    )
}

export default Body
