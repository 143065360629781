
import React, { useState } from 'react';
import './ListQR.scss';
import QRItemWallet from '../qrItemWallet/QRItemWallet'
import search from '../../img/search/search.svg'
const ListQR = () => {
    const data = [
        {

            id: 0,
            name: "one",
            img: ''
        },
        {
            id: 1,
            name: "two",
            img: ''
        },
        {
            id: 2,
            name: "three",
            img: ''
        },
        {
            id: 3,
            name: "four",
            img: ''
        },
        {
            id: 4,
            name: "five",
            img: ''
        },
        {
            id: 5,
            name: "six",
            img: ''
        },
        {
            id: 6,
            name: "seven",
            img: ''
        },
    ];
    const [value, setValue] = useState('');

    const filterWallets = data.filter(e => {
        return e.name.toLowerCase().includes(value.toLowerCase())
    })

    return (
        <>

            <div className="listQrContainer">
                <div className="qrText">Choose your preferred wallet</div>

                <div className="d1">
                    <form className='searchForm' action="">
                        <input type="text"
                            placeholder='search'
                            className='searchInput'
                            onChange={(event) => setValue(event.target.value)}
                        />
                        <button><img src={search} alt="img" /></button>


                    </form>
                </div>
                <div className="containerWallets">
                    {
                        filterWallets.map(e => {
                            return <QRItemWallet key={e.id} name={e.name} />


                        })
                    }
                </div>
                <div className="paginatorContainer">
                    <button>1</button>
                    <button>2</button>
                    <button>3</button>
                </div>
            </div>
        </>
    )
}

export default ListQR