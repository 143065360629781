import React from 'react'
import './Withdraw.css'
import InputWithPercent from '../../components/inputWithPercent/InputWithPercent'
import WithDrawVariants from '../../components/withDrawVariants/WithDrawVariants'
import ButtonWithdraw from '../../components/buttonWithdraw/ButtonWithdraw'
import XNFT from '../../img/icon/XNFT.png'
const Withdraw = (props) => {

    return (
        <>
            <div className="withdrawBackGroundImg"></div>
            <div className=" withdrawContainer">
                <div className="withdrawBlockTitle">
                    <div className="blockTitle"> <h2>Withdraw</h2>  </div>
                    <div className="line"></div>

                </div>
                <span> XNFT DAO </span>
                <div className="withdrawBlockSearch">
                    <div className="withdrawBlockSearchInputs">
                        <InputWithPercent activ={false} error={false} icon={XNFT} number="24,08" text="3,2%" arrowdown={false} loading={false} />
                        <div className="containerOversize">
                            <div className="withdrawBlockSearchText">
                                Your application is accepted. Your line number is 25
                            </div>

                            <WithDrawVariants />
                        </div>
                    </div>
                    <div className="withdrawBlockSearchButtons">
                        <ButtonWithdraw condition='' />
                    </div>
                </div>
            </div>



            <div className="accountBlock">

            </div>


        </>
    )
}

export default Withdraw