import React from "react";
import './Input.css';



const Input = ({ activ, error, icon, icon2, topText, isWeth, downText, number, ...props }) => {
    return (
        <>
            <div className={`wrapperInput ${isWeth ? "isWeth " : ''}${activ ? "activ " : ''}${error ? "error " : ''}`}>
                <div className="inputInfo">
                    {
                        icon2
                            ?
                            <div className="inputDuble">
                                <div className="inputIcon mini miniTop"> <img src={icon} alt="coins" /></div>
                                <div className="mini miniLine"/>
                                <div className="inputIcon mini miniBottom"> <img src={icon2} alt="coins" /></div>
                            </div>
                            :
                            <div className="inputIcon"> <img src={icon} alt="coins" /></div>
                    }

                    <div className="inputTexts">
                        <div className="topText">{topText}</div>
                        <div className="downText">{downText}</div>
                    </div>
                </div>
                <div className="inputNumber">
                    <input placeholder={number} type="number"  {...props} />
                </div>
            </div>
        </>
    )
}

export default Input
