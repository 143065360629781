import React, { useState } from "react";
import './WithDrawVariants.css';
import WithDrawVariant from '../withDrawVariant/WithDrawVariant'
import WithDrawReady from '../withDrawReady/WithDrawReady'


const withdrawArray = [
    { number: 1, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: true },
    { number: 2, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '12', name: 'XNFT', loading: true },
    { number: 3, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '132', name: 'XNFT', loading: false },
    { number: 4, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '10', name: 'XNFT', loading: false },
    { number: 5, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '2', name: 'XNFT', loading: false },
    { number: 6, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: false },
    { number: 7, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '22', name: 'XNFT', loading: false, activ: true },
    { number: 8, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '3', name: 'XNFT', loading: false },
    { number: 9, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '123', name: 'XNFT', loading: false },
    { number: 10, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '31', name: 'XNFT', loading: false },
    { number: 11, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: false },
    { number: 12, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '1312', name: 'XNFT', loading: false },
    { number: 13, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: false },
    { number: 14, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '13231', name: 'XNFT', loading: false },
    { number: 15, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: false },
    { number: 16, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: false },
    { number: 17, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: false },
    { number: 18, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: false },
    { number: 19, cod: 'Ox36AfDBaD8248Fec3A909F6250CE1D6EC9B44Cb9d', quantity: '4', name: 'XNFT', loading: false },
]
let Variants = withdrawArray.map(e => <WithDrawVariant key={e.number} number={e.number} cod={e.cod} quantity={e.quantity} name={e.name} loading={e.loading} activ={e.activ} />)
let VariantsCrop = Variants.slice(1, -1)

const WithDrawVariants = ({ ...props }) => {
    //  {readyShow} показать модальное окно Ready
    const [readyShow, setReadyShow] = useState(false);
    return (
        <div className="withdrawVariants">
            {readyShow && <WithDrawReady setReadyShow={setReadyShow}/>}
            
            <div className="variantFirst">{Variants?.[0]}</div>
            <div className="variants">
                {VariantsCrop}
            </div>
            <div className="variantLast">{Variants?.[Variants?.length - 1]} </div>
            <div className="paginatorContainer white">
                <button>1</button>
                <button>2</button>
                <button>3</button>
            </div>
        </div>

    )
}

export default WithDrawVariants