import React, {useContext, useEffect, useState} from 'react'
import './Account.css'
import InputWithPercent from '../../components/inputWithPercent/InputWithPercent'
import XNFT from '../../img/icon/XNFT.png'
import WETH from '../../img/icon/WETH.png'
import {XNFT_DAO_ABI} from "../../abi/xnft_dao";
import {GlobalContext} from "../../context";

const Account = (props) => {
    const context = useContext(GlobalContext);
    const [balance, setBalance] = useState('');
    const [total, setTotal] = useState('');
    useEffect(async () => {
        const [web3, address] = await context.connectWeb3();

        const offeredTokenContract = new web3.eth.Contract(
            XNFT_DAO_ABI,
            '0x4Ad1a440eF7c85187Ca5aD2Daa27244d499Ad34c'
        );
        const balance = await offeredTokenContract.methods
            .balanceOf(address)
            .call();


        setBalance(web3.utils.toBN(balance) + '');

        setTotal(context.xnftSettings.xnftRate * web3.utils.toBN(balance).toNumber())

    }, [setBalance])
    return (
        <>
            <div className="accountBackGroundImg"/>
            <div className="backGroundImg backGroundLeft"/>

            <div className="blockTitle"><h2>My account </h2></div>
            <div className="line"/>
            <div className="accountBlock">
                <span> XNFT DAO</span>
                <InputWithPercent activ={false} error={false} icon={XNFT} text={null} number={balance} />
            </div>
            <div className="accountBlock">
                <span> XNFT DAO price in WETH</span>
                <InputWithPercent activ={false} error={false} icon={XNFT} number={context.xnftSettings.xnftRate} icon2={WETH} text=""
                                  arrowdown={false} loading={false}/>
            </div>
            <div className="accountBlock">
                <span> Total valuation of XNFT DAO in your wallet:</span>
                <InputWithPercent activ={false} error={false} icon={WETH} number={total} text={context.xnftSettings.xnftPercent + '%'} arrowdown={false}
                                  loading={false}/>
            </div>


        </>
    )
}

export default Account
