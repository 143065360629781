import "./App.scss";
import Home from "./wrapper/home/Home";
import {BrowserRouter as Router} from "react-router-dom";
import {GlobalContext} from "./context";
import {useCallback, useEffect, useState} from "react";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import {getSiteSettings} from "./helpers/xnft-rate";
import {XNFT_DAO_ABI} from "./abi/xnft_dao";

const contextInit = {
    setContext: (key, value) => {
        this[key] = value;
        console.log(value);
    },
};
contextInit.setContext.bind(contextInit);

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: "8b4d1480572f4bda9fa9eb8d7ca8decb",
        },
    },
};

const addCoinToWallet = async () => {
    // await window.ethereum.request({
    //     method: "wallet_addEthereumChain",
    //     params: [
    //         {
    //             chainId: `0x${Number(56).toString(16)}`,
    //             chainName: "Binance Smart Chain Mainnet",
    //             nativeCurrency: {
    //                 name: "BNB",
    //                 symbol: "BNB",
    //                 decimals: 18,
    //             },
    //             rpcUrls: ["https://bsc-dataseed.binance.org/"],
    //             blockExplorerUrls: ["https://bscscan.com"],
    //         },
    //     ],
    // });
    await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x1' }]
    })
}

const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    theme: "dark",
    providerOptions,
});

function App() {
    const [connected, setConnected] = useState(false);
    const [address, setAddress] = useState("");
    const [xnftSettings, setXnftSettings] = useState({
        xnftRate: '',
        xnftPercent: ''
    });

    const logout = useCallback(() => {
        window.localStorage.removeItem('connected')
        setAddress('')
        setConnected(false)
    }, [setAddress, setConnected])

    useEffect(async () => {
        getSiteSettings().then(xnftSetting => setXnftSettings(xnftSetting))
        const connected = !!window.localStorage.getItem('connected');
        setConnected(connected);
        const [web3, address] = await connectWeb3();
        setAddress(address);
    }, [])

    const connectWeb3 = useCallback(async () => {
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        const address = await web3.currentProvider.enable();
        if (address[0]) {
            setAddress(address[0]);
            setConnected(true)
            window.localStorage.setItem('connected', 'connected');
            addCoinToWallet();
        }
        return [web3, address[0]];
    }, [setConnected]);

    return (
        <GlobalContext.Provider
            value={{
                xnftSettings,
                connectWeb3,
                address,
                logout,
                connected
            }}
        >

            <Router>
                <div className="App">
                    <Home></Home>
                </div>
            </Router>

        </GlobalContext.Provider>
    );
}

export default App;
