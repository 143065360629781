import React from "react";
import './InputWithPercent.css';
import arrow from '../../img/withdraw/arrow-up.svg'
import loader from '../../img/withdraw/loader.svg'


const InputWithPercent = ({ activ, error, icon, icon2, text, isWeth, number, arrowdown, loading, ...props }) => {

    return (
        <>
            <div className={`wrapperInput ${isWeth ? "isWeth " : ''}${activ ? "activ " : ''}${error ? "error " : ''}`}>
                <div className="inputInfo">
                    {
                        icon2
                            ?
                            <div className="inputDuble">
                                <div className="inputIcon mini miniTop"> <img src={icon} alt="coins" /></div>
                                <div className="mini miniLine"></div>
                                <div className="inputIcon mini miniBottom"> <img src={icon2} alt="coins" /></div>
                            </div>
                            :
                            <div className="inputIcon"> <img src={icon} alt="coins" /></div>
                    }

                    <div className="InputWPNumber">
                        <input placeholder={number} type="number" />

                    </div>
                </div>
                <div className="InputWPText">
                    {loading ?
                        <div className="inputWPTextLoading"><img className="inputWPloader" src={loader} alt="loader" /> <div className="flex">Make order</div> </div> 
                        :    
                        <>
                            {text && <><img className={`InputWPArrow ${arrowdown && "arrowdown"}`} src={arrow} alt="Arrow" /> <div className="flex">{text}</div></>}
                        </>
                    }


                </div>
            </div>
        </>
    )
}

export default InputWithPercent