
import React from 'react';
import './QRItemWallet.scss';


const QRItemWallet = (props) => {

    return (
        <>
            <div className="containerQrItem">
                <div className="qrItemImg"></div>
                <div className="qrItemName">{props?.name}</div>
            </div>
        </>
    )
}

export default QRItemWallet