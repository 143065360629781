import React from "react";
import './ButtonWithdraw.css';
import loader from '../../img/withdraw/loader.svg'
import ok from '../../img/withdraw/ok.svg'
import no from '../../img/withdraw/no.svg'



const ButtonWithdraw = ({ condition, ...props }) => {


    return (
        <>
            {(() => {
                switch (condition) {
                    // Процесс проверки
                    case 'verification':
                        return <div className="withdrawButton buttonVerification">verification<img className="inputWPloader" src={loader} alt="loader" /></div>;
                    // подтверждено
                    case 'confirmed':
                        return <div className="withdrawButton buttonConfirmed">confirmed<img src={ok} alt="loader" /></div>;
                    // Недостаточно средств
                    case 'rejected':
                        return <div className="withdrawButton buttonRejected">rejected<img src={no} alt="loader" /></div>;
                    // Очередь на вывод
                    case 'withdrawLoad':
                        return <div className="withdrawButton buttonLoad">Withdrawal<img className="inputWPloader" src={loader} alt="loader" /></div>;
                    // монеты выведены
                    case 'withdrawOk':
                        return <div className="withdrawButton buttonOk">Withdraw<img src={ok} alt="loader" /></div>;
                    // До клика 
                    default:
                        return <div className="withdrawButton buttonDefolt">Withdraw</div>;
                }
            })()}
        </>
    )
}

export default ButtonWithdraw