import React, { useState } from 'react';
import './DisconnectWallet.scss'
import Cross from '../../components/cross/Cross'

import accountWallet from '../../img/menu/account_balance_wallet.png';
import tr from '../../img/menu/tr.svg';
import link from '../../img/menu/link.svg';


const DisconnectWallet = (props) => {
    //  {DisconnectWalletShow} показать модальное окно DisconnectWallet
    const [DisconnectWalletShow, setDisconnectWalletShow] = useState(false);

    return (
        <>{DisconnectWalletShow ?
            <div className="disconWal_back">
                <div className="disconWal_wrapper" >
                    <Cross set={setDisconnectWalletShow} color="black" />
                    <div className="disconWalTitle">
                        <span>
                            Do you really want <br />
                            to disconnect wallet?
                        </span>
                        <span>
                            Connect wallet</span>
                    </div>
                    <div className="disconWalName">
                        <div className="disconWalNameLeft">
                            <img src={accountWallet} alt="balance" />
                            <img src={tr} alt="balance" className="disconWalTr" />
                            Name wallet
                        </div>

                        <img src={link} alt="balance" />
                    </div>
                    <div className="disconWalButtons">
                        <div className="disconWalButton green">Disconnect</div>
                        <div className="disconWalButton">Leave</div>
                    </div>

                </div>
            </div> : <></>
        }
        </>
    )
}

export default DisconnectWallet