
import React from 'react';
import './QRCod.scss';
import qrCod from '../../img/QR/qrCod.svg'

const QRCod = () => {
    return (
        <>
            <div className="qrContainer">
                <div className="qrText">Scan QR code with a WalletConnect-compatible wallet</div>
                <img src={qrCod} alt="QR" />
                <div className="qrText">Copy to clipboard</div>
            </div>

        </>
    )
}

export default QRCod