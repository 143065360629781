import React from "react";
import './ButtonConnect.css';
import out from '../../img/menu/icon_out.svg';
import balance from '../../img/menu/account_balance_wallet.svg';


const ButtonConnect = ({connectButton, name, address, style, buttonAction, ...props}) => {

    return (
        <>
            {
                connectButton
                    ?
                    <div className="flex buttonConnectWallet">
                        <button type="button" className={`button ${style}`}>
                            <img src={balance} className='balance ' alt="balance"/>
                            {/*                                                        loading*/}
                            {/*<div className='containerinfoIcon'>
                                <div className='infoIcon connectStatik'/>
                            </div>*/}
                            <div className="lineEffect">
                                {`${address.slice(0, 4)}....${address.slice(address.length - 4, address.length)}`}
                            </div>

                        </button>
                        <button type="button " className={`button ${style} out`} onClick={buttonAction}>
                            <img src={out} alt="out"/>
                        </button>
                    </div>
                    :
                    <button type="button" className={`button ${style}`} onClick={buttonAction}>
                        <img src={balance} className='balance ' alt="balance"/>
                        <div className="lineEffect">
                            {name}
                        </div>

                    </button>
            }

        </>
    )
}

export default ButtonConnect
