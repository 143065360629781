import React, {useCallback, useContext, useState} from 'react';
import './Header.scss';
import Burger from '../../components/burger/Burger';
import { NavLink } from 'react-router-dom';
import Button from '../../components/button/Button'
import ButtonConnect from '../../components/buttonConnect/ButtonConnect'
import logo from '../../img/logo/logo.png';
import ConnectWallet from '../connectWallet/ConnectWallet';
import DisconnectWallet from '../disconnectWallet/DisconnectWallet';
import balance from '../../img/menu/account_balance_wallet.svg';
import {GlobalContext} from "../../context";

const Home = () => {

    const context = useContext(GlobalContext);
    const {connected, connectWeb3, logout, address} = context;
    const buttonAction = useCallback(() => {
        connected? logout(): connectWeb3();
    } ,[connected])

    return (
        <>
            <div className="backGroundHeader"/>
            <div className="header_container">
                <NavLink className="header_logo" to="/">
                    <img src={logo} className='balance' alt="out" />
                </NavLink>
                <div className="rigth_container">
                    {
                        connected
                            ?
                            <button type="button" className="balanceContainer">
                                <img src={balance} alt="balance" />
                                <div className='loadingMobileStatic' />
                                <div className="loadingMobileD" />
                            </button>
                            :
                            <button type="button" className="balanceContainer">
                                <img src={balance} alt="balance" />
                            </button>
                    }

                    <div className="menu_items">
                        <NavLink to="/account" >
                            <Button name="My&nbsp;account" style="transparent" />
                        </NavLink>
                        <ButtonConnect connectButton={connected} buttonAction={buttonAction} address={address} name="Connect wallet" style="halvetransparent" />


                    </div>
                </div>
            </div>
            <ConnectWallet />
            <DisconnectWallet />
            <Burger  />
        </>
    )
}
export default Home
