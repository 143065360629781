import React from "react";
import './Button.css';
import out from '../../img/menu/icon_out.svg';
import balance from '../../img/menu/account_balance_wallet.svg';




const Button = ({ name, style, setOut, ...props }) => {

    return (
        <>
            {
                <button type="button" className={`button ${style}`} {...props}>
                    <div className="lineEffect">
                        {name}
                    </div>
                </button>
            }

        </>
    )
}

export default Button
